#Auswahl {
    font-weight: bold;
    
}
.abstand {
    margin:0.3em;
    height: 6em;
}
#Warenkorb {
    font-weight: bold;
    
}

.warenkorbitem{
    font-weight: normal;
    margin: 0.1em;
}

#fehlerort{
    font-weight: bold;
    margin-bottom: 1em;
}

#SendenButton{
    height: 6em;
}

#ContentContainer{
    height: 100px;
}

.main-container::backdrop {
    background-color: rgba(255,255,255,0);
}